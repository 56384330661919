<template>
  <v-card class="pa-4" flat>
    <v-card-title class="font-weight-bold">
      {{ $t("absent.absent_per_subjects") }}
    </v-card-title>
    <v-card-text>
      <Table />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  components: {
    Table: () => import("./components/absentPerSubjects/Table")
  }
};
</script>
